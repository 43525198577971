<template>
	<div class="partial partial--auth-login auth-login">
		<form-login class="auth-login__form" />
	</div>
</template>

<script>
import FormLogin from '@/components/FormLogin'

export default {
	name: 'AuthLogin',
	components: {
		FormLogin,
	},
}
</script>

<style scoped lang="scss">
.auth-login {
	display: flex;
	flex-direction: column;

	@include modules.media-query('phone') {
		flex-direction: column-reverse;
	}

	&__form {
		@include modules.gutter('padding');
		background-color: rgba(modules.color_('background'), 0.9);
		//backdrop-filter: blur(4px);
		border: 1px solid rgba(modules.color_('background'), 0.8);

		// flex: 1;
		max-width: 640px;
		width: 100%;
	}

	&__header {
		@include modules.gutter('margin-bottom');

		text-align: right;

		span {
			@include modules.gutter('padding-right');
			flex: 1;

			@include modules.media-query('phone') {
				@include modules.gutter('padding-right', 0);
			}
		}

		@include modules.media-query('phone') {
			align-items: center;
			justify-content: space-between;
			display: flex;
			text-align: left;
		}
	}
}
</style>
