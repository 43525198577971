<template>
	<div class="auth-verify">
		<div class="auth-verify__body">
			<template v-if="authLoading">
				<loading-spinner />
				<h3 class="auth-verify__status">Authenticating...</h3>
			</template>
			<template v-if="authError">
				<ion-icon name="alert" size="large" class="auth-verify__icon"></ion-icon>
				<div class="auth-verify__error">
					<p v-html="authError">{{ authError }}</p>
					<p><a :href="portalUrl">Please visit the Correspondent Dashboard for support</a></p>
				</div>
			</template>
			<template v-if="!authError && !authLoading">
				<ion-icon name="unlock" size="large" class="auth-verify__icon"></ion-icon>
				<router-link :to="{name: 'main'}">Access Now</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'AuthVerify',
	components: {
		LoadingSpinner,
	},
	data: () => ({
		portalUrl: process.env.PORTAL_URL || process.env.VUE_APP_PORTAL_URL,
	}),
	computed: {
		authLoading() {
			return this.$store.getters['user/authLoading']
		},
		authError() {
			return this.$store.getters['user/authError']
		},
	},
	metaInfo: () => ({
		title: 'Authenticating...',
	}),
}
</script>

<style scoped lang="scss">
.auth-verify {
	&__body {
		align-items: center;
		background-color: rgba(modules.color_('background'), 0.9);
		//backdrop-filter: blur(4px);
		border: 1px solid rgba(modules.color_('background'), 0.8);
		border-radius: modules.$border-radius;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 320px;
		width: 320px;
	}

	&__error {
		@include modules.gutter('padding');
		text-align: center;
	}

	&__icon {
		@include modules.gutter('margin-bottom', 0.5);
		color: modules.color_('primary');
	}
}
</style>
