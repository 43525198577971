<template>
	<button class="button button--raised">
		<div class="mdc-button__ripple"></div>
		<span class="mdc-button__label"><slot></slot></span>
		<div class="mdc-button__touch"></div>
	</button>
</template>

<script>
import { MDCRipple } from '@material/ripple'

export default {
	name: 'AButton',
	mounted() {
		new MDCRipple(this.$el)
	},
}
</script>

<style scoped lang="scss">

</style>
