<template>
	<form class="form form--login form-login" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<h1 class="form-section__title">Log In</h1>

			<div class="form-row">
				<text-field class="form__field" label="Email Address" id="login_email" name="email" type="email" autocomplete="email" :required="true" v-model="fields.email" />
			</div>
			<div class="form-row">
				<text-field class="form__field" label="Password" id="login_password" name="password" type="password" autocomplete="password" :required="true" v-model="fields.password" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--secondary form__action" type="submit" :disabled="isLoading">Sign In</a-button>
				<a @click="lostPasswordConfirm(fields)" class="button button--secondary button--dark form__action">Forgot Password?</a>
			</div>
		</div>

	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'formLogin',
	components: {
		AButton,
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		fields: {
			email: '',
			password: '',
		},
		error: undefined,
		isLoading: false,
	}),
	computed: {
		// loading() {
		// 	return this.$store.getters['misc/loading']
		// },
	},
	methods: {
		submitForm(fields) {
			this.error = undefined
			this.isLoading = true

			this.$store.dispatch('user/login', fields).then((res) => {
				if (this.$route.query && this.$route.query.redirect_uri) {
					let redirect = this.$route.query.redirect_uri
					try {
						let queryParams = new URLSearchParams(this.$route.query.redirect_uri.split('?')[1])
						queryParams.set('token', res.token)
						redirect = `${this.$route.query.redirect_uri}?${queryParams.toString()}`
					} catch (error) {
						console.error(error)
					}
					window.location.href = redirect
				} else {
					if (this.$route.query && this.$route.query.from) {
						this.$router.push({ path: this.$route.query.from })
					} else {
						this.$router.push({ name: 'main' })
					}
				}
			}).catch((error) => {
				console.log('catch.error', error)
				this.error = error.message || error
			}).finally(() => {
				this.isLoading = false
			})
		},
		lostPasswordConfirm(fields) {
			this.$confirm(`This will send you an email with instructions about how to reset your password.`,
				() => {
					this.lostPassword(fields)
				},
				{
					title: `Send Password Reset?`,
					acceptLabel: `Yes, Send`,
				},
			)
		},
		lostPassword(fields) {
			this.error = null
			this.loading = true
			if (!fields.email) {
				this.loading = false
				return this.error = `Please enter an email address`
			}

			this.$store.dispatch('user/recover', fields.email).then(() => {
				this.$notice(`You have been sent an email with password recovery instructions.`)
			}).catch(error => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	text-align: center;

	&__fields {
		display: flex;
		flex-direction: column;
	}

	&__field {
		@include modules.gutter('margin-bottom');
	}

	&__actions {
		display: flex;
		flex-direction: column;
	}

	&__action {
		margin-bottom: 1em;
	}
}
</style>
