<template>
	<div class="view view--auth auth">
		<header class="auth__header">
			<img src="@/assets/ARC-Logo--white.png" class="auth__header-logo" alt="ARC" /> ARC PRICING
		</header>
		<router-view class="auth__content" name="content" />
	</div>
</template>

<script>
export default {
	name: 'ViewAuth',
}
</script>

<style scoped lang="scss">
.auth {
	background-image: url('~@/assets/bg__auth.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	width: 100%;

	&:before {
		background-color: modules.color_('primary');
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: .3;
	}

	&__header {
		@include modules.fontSize(20px);
		align-items: center;
		background-color: modules.color_('background', 'dark');
		color: modules.color_('primary', 'text');
		display: flex;
		font-weight: bold;
		justify-content: center;
		height: 3.75rem;
		letter-spacing: 2.5px;
		position: relative;

		&-logo {
			margin-right: 1rem;
			width: 54px;
		}
	}

	&__content {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: center;
		position: relative;
	}
}
</style>
